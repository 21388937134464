@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


@font-face {
  font-family: "Rajdhani";
  src: url("./fonts/rajdhani-bold-webfont.woff2") format("woff2"),
       url("./fonts/rajdhani-bold-webfont.woff") format("woff");
  font-weight: bold;
}